<template>
  <div class="home">
    <h3 class="common-title">常见问题</h3>
    <div class="clearfix">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="commonQuestionFilter.keyword" placeholder="请输入关键词" clearable
                  @keyup.enter="getCommonQuestionList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getCommonQuestionList">
          检索
        </el-button>
      </div>
      <div class="clearfix pb20">
        <div class="fr">
          <el-button type="primary" @click="newCommonQuestion">追加</el-button>
        </div>
      </div>
    </div>
    <div class="home-left">
      <el-container>
        <el-main>
          <Empty v-if="commonQuestionList.length === 0"></Empty>
          <el-table
            :data="commonQuestionList"
            class="home-list"
            style="width: 100%"
            height="calc(100vh - 600px)"
            v-else>
            <el-table-column
              label="ID"
              prop="id"
              width="80">
            </el-table-column>
            <el-table-column
              prop="question"
              label="问题">
            </el-table-column>
            <el-table-column
              prop="answer"
              label="回答">
            </el-table-column>
            <el-table-column label="操作" width="120px">
              <template #default="scope">
                <el-button
                  type="primary"
                  link
                  @click="editCommonQuestion(scope.row)">
                  编辑
                </el-button>

                <el-button
                  type="danger"
                  link
                  @click="deleteCommonQuestion(scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </div>

    <el-dialog v-model="commonQuestionFormRef" :title="commonQuestionForm.id === null ? '新增':'编辑'" width="40%"
               destroy-on-close
               draggable>
      <span v-loading="loading">
        <el-form
          ref="commonQuestionRuleFormRef"
          :model="commonQuestionForm"
          :rules="commonQuestionRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="问题" prop="question">
          <el-input type="textarea" rows="3" v-model="commonQuestionForm.question"></el-input>
        </el-form-item>
        <div v-for="config in merchantServerLanguages">
          <el-form-item :label="config.name + '问题'" :prop="config.prefix + '_question'" v-if="config.is_active">
            <el-input type="textarea" rows="3"
                      v-model="commonQuestionForm.multilingual_data[config.prefix + '_question']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="回答" prop="answer">
           <el-input type="textarea" rows="3" v-model="commonQuestionForm.answer"></el-input>
        </el-form-item>
        <div v-for="config in merchantServerLanguages">
          <el-form-item :label="config.name + '回答'" :prop="config.prefix + '_answer'" v-if="config.is_active">
            <el-input type="textarea" rows="3"
                      v-model="commonQuestionForm.multilingual_data[config.prefix + '_answer']"></el-input>
          </el-form-item>
        </div>
        </el-form></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="commonQuestionFormRef = false">取消</el-button>
        <el-button type="primary" @click="createCommonQuestion">
          {{ commonQuestionForm.id === null ? '新增' : '确定' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant

    const merchant = computed(() => {
      return store.getters.merchant
    })
    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const loading = ref(false)

    // common question
    const commonQuestionList = ref([])
    const commonQuestionListDialog = ref(false)

    const getCommonQuestionList = (reset) => {
      // chatHistory.value = []
      loading.value = true
      authApi.getCommonQuestionList(merchant.value.id, commonQuestionFilter).then(res => {
        commonQuestionList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    const commonQuestionFormRaw = reactive({
      id: null,
      question: null,
      answer: null,
      multilingual_data: {},
    })
    const commonQuestionForm = reactive(JSON.parse(JSON.stringify(commonQuestionFormRaw)))
    const commonQuestionFormRef = ref(false)

    const commonQuestionRuleFormRef = ref(null)

    const commonQuestionRules = reactive({
      question: [
        { required: true, message: '请输入问题', trigger: 'blur' },
      ],
      answer: [
        { required: true, message: '请输入答案', trigger: 'blur' },
      ],
    })

    const commonQuestionFilter = reactive({
      keyword: null,
    })

    const newCommonQuestion = () => {
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = commonQuestionFormRaw[key]
      })
      commonQuestionFormRef.value = true
    }
    const editCommonQuestion = (item) => {
      console.log(item)
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = item[key]
      })
      commonQuestionFormRef.value = true
    }
    const deleteCommonQuestion = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除常见问题',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteCommonQuestion(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getCommonQuestionList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createCommonQuestion = () => {
      commonQuestionRuleFormRef.value.validate((valid) => {
        if (valid) {

          loading.value = true
          if (commonQuestionForm.id === null) {
            authApi.createCommonQuestion(merchant.value.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              commonQuestionFormRef.value = false
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateCommonQuestion(merchant.value.id, commonQuestionForm.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              commonQuestionFormRef.value = false
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }


    onMounted(() => {
      getCommonQuestionList()
    })
    return {
      loading,
      merchant,
      merchantServerLanguages,
      commonQuestionList,
      commonQuestionListDialog,
      newCommonQuestion,
      createCommonQuestion,
      commonQuestionForm,
      commonQuestionFormRef,
      commonQuestionRuleFormRef,
      commonQuestionRules,
      commonQuestionFilter,
      getCommonQuestionList,
      editCommonQuestion,
      deleteCommonQuestion,

    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
